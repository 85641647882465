import React, { useEffect, useState } from "react";
import axios from "axios";

const MySubscriptions = ({ userId, email }) => {
  const [subscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await axios.get(
          `https://d4gscm5o97.execute-api.us-east-1.amazonaws.com/prod/subscriptions/${userId}`
        );
        setSubscription(response.data);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [userId]);

  if (loading) {
    return (
      <div className="text-center text-gray-400">Loading subscriptions...</div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gradient-to-r from-gray-800 via-gray-900 to-black shadow-lg rounded-lg text-white">
      <h2 className="text-2xl font-semibold mb-6">Your Subscriptions</h2>
      <ul className="mb-6 space-y-4">
        {
          <li
            key={subscription.subscription_id}
            className="border-b border-gray-600 pb-4"
          >
            <span className="font-medium">Product:</span>{" "}
            {subscription.product_internal_id} <br />
            <span className="font-medium">Status:</span>{" "}
            {subscription.subscription_status}
          </li>
        }
      </ul>
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-4">
          Manage Your Subscriptions
        </h2>
        <a
          href={
            "https://billing.stripe.com/p/login/test_eVa3fO2nM3EGdWw7ss/?prefilled_email=" +
            email
          }
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-700 transition duration-300"
        >
          Manage on Stripe
        </a>
      </div>
    </div>
  );
};

export default MySubscriptions;
