/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:3b9a6e8c-892d-46dc-9588-608018f0ce6d",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_xg1f3RlbZ",
  aws_user_pools_web_client_id: "2pc47at81sehq0kdb5gjd61l7u",
  oauth: {
    domain: "sapienslab.auth.us-east-1.amazoncognito.com",
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn:
      window.location.hostname === "localhost"
        ? "https://localhost:3000/controlpanel"
        : "https://sapienslab.net/controlpanel",
    redirectSignOut:
      window.location.hostname === "localhost"
        ? "https://localhost:3000"
        : "https://sapienslab.net",
    responseType: "code",
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  Storage: {
    AWSS3: {
      bucket: "sapienslab-storage-dev-user-files",
      region: "us-east-1",
    },
  },
};

export default awsmobile;
