import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ChatBox from "../components/ChatBox";

const ChatInterface = ({ chatBotId }) => {
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="sm:grid-cols-12 bg-gray-900 text-white  items-center justify-center">
      <ChatBox chatBotId={chatBotId} />
    </div>
  );
};

export default ChatInterface;
