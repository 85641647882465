import { ProductsGrid } from "../components/ProductsGrid";
export function LandingPage() {
  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-6xl bg-gray-800 shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-4">Select an AI Sapiens</h1>
        <ProductsGrid />
      </div>
    </div>
  );
}
