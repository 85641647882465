import React, { useEffect, useState } from "react";
import PricingTable from "./PricingTable";
import { useAuthenticator } from "@aws-amplify/ui-react";
import MySubscriptions from "./MySubscriptions";
import axios from "axios";

const SubMngmt = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const [hasActiveSubscription, setHasActiveSubscription] = useState(null); // null indicates loading

  useEffect(() => {
    const checkActiveSubscriptions = async () => {
      try {
        const response = await axios.get(
          `https://d4gscm5o97.execute-api.us-east-1.amazonaws.com/prod/subscriptions/${user.attributes.sub}`
        );

        // The response data includes 'statusCode', 'body', and 'headers'
        // 'body' is a JSON string, so parse it
        // const subscription = response.data;

        // Check if the subscription is active
        if (response.data.subscription_status === "active") {
          setHasActiveSubscription(true);
        } else {
          setHasActiveSubscription(false);
        }
      } catch (error) {
        console.error("Error checking subscriptions:", error);
        // Handle error by assuming no active subscription
        setHasActiveSubscription(false);
      }
    };

    checkActiveSubscriptions();
  }, [user.attributes.sub]);

  if (hasActiveSubscription === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Subscription Management</h2>

      {hasActiveSubscription ? (
        <MySubscriptions
          userId={user.attributes.sub}
          email={user.attributes.email}
        />
      ) : (
        <PricingTable
          email={user.attributes.email}
          user_id={user.attributes.sub}
        />
      )}
    </div>
  );
};

export default SubMngmt;
