import React, { useState, useEffect, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa"; // Import hamburger and close icons

export default function Sidebar({
  subscribedProducts = [],
  setSelectedChatBotId,
}) {
  const [isOpen, setIsOpen] = useState(false); // Changed to false to hide sidebar by default
  const [isMobile, setIsMobile] = useState(false);

  // Add ref for the sidebar
  const sidebarRef = useRef(null);
  const buttonRef = useRef(null);

  // Handle clicks outside the sidebar
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Function to update `isMobile` based on the screen size
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  // Close sidebar on mobile when a chatbot is selected
  const handleChatBotSelection = (productId) => {
    setSelectedChatBotId(productId);
    if (isMobile) {
      setIsOpen(false);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      {/* Hamburger button - visible on all screen sizes */}
      <button
        ref={buttonRef}
        onClick={toggleSidebar}
        className="text-xl text-white p-2 focus:outline-none absolute top-2 left-2 z-30"
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 left-0 h-full bg-gray-800 p-4 transition-transform duration-300 ease-in-out transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } w-64 z-20`}
      >
        <h2 className="text-xl font-bold mb-4">Control Panel</h2>
        <ul>
          {subscribedProducts.length > 0 ? (
            subscribedProducts.map((productId) => (
              <li
                key={productId}
                className="cursor-pointer hover:bg-gray-700 p-2 rounded"
                onClick={() => handleChatBotSelection(productId)}
              >
                ChatBot {productId}
              </li>
            ))
          ) : (
            <li className="p-2">No available chatbots.</li>
          )}
        </ul>
      </div>
    </div>
  );
}
