import React from "react";

const PricingTable = ({ email, user_id }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
          <stripe-pricing-table
            pricing-table-id="prctbl_1QIjLbLCKcqaoophbaavMNAC"
            publishable-key="pk_live_51O0Hx7LCKcqaoopheYNbL5hukbPWFLCWZ2BBEXKavp7r80XZCcyl3jMh8a939j82wSoOqKF49cmkJvbHoUnW8ic400fWuwttmV"
            customer-email="${email}"
            client-reference-id="${user_id}">
          </stripe-pricing-table>
        `,
      }}
    />
  );
};

export default PricingTable;
