import React, { useState, useEffect, useRef } from "react";
import { post_ai, retrieve_messages, check_job_status } from "../utils/postAI";
import { Auth } from "aws-amplify";
import { FaPaperPlane } from "react-icons/fa"; // Import the paper plane icon
import ReactMarkdown from "react-markdown"; // Import react-markdown
import FileUpload from "./FileUpload";

const ChatBox = ({ chatBotId }) => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState("");
  const [jobId, setJobId] = useState(null);
  const [attachedFile, setAttachedFile] = useState(null);

  // Add a ref for the messages container
  const messagesEndRef = useRef(null);

  // Add scroll to bottom function
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Add useEffect to scroll on new messages
  useEffect(() => {
    scrollToBottom();
  }, [messages]); // This will trigger whenever messages array changes

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const userId = user.attributes.sub;
        setUserId(userId);
        document.cookie = `user_id=${user.attributes.sub}; path=/; SameSite=None; Secure`;
        fetchMessages(userId, chatBotId);
      })
      .catch((err) => console.error("Error fetching user:", err));
  }, [chatBotId]);

  const fetchMessages = async (userId, chatBotId) => {
    try {
      const retrievedMessages = await retrieve_messages(userId, 10, chatBotId);
      setMessages(retrievedMessages);
    } catch (error) {
      console.error("Error retrieving messages:", error);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePostRequest();
    }
  };

  const handlePostRequest = async () => {
    if (!input.trim() && !attachedFile) return; // Prevent sending empty messages

    // Construct message content combining text and file
    const messageContent = [
      input.trim(),
      attachedFile ? `[File: ${attachedFile.name}](${attachedFile.url})` : "",
    ]
      .filter(Boolean)
      .join("\n");

    const userMessage = {
      id: messages.length + 1,
      type: "human",
      msg: messageContent,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    const data = {
      human_input: input.trim(),
      file_url: attachedFile?.fullPath || null,
      file_type: attachedFile?.type || null,
      bot_id: chatBotId,
      user_id: document.cookie.split("=")[1],
    };

    try {
      const result = await post_ai("enqueue", data, chatBotId);
      const newJobId = result.job_id;
      setJobId(newJobId);

      // Start polling for job status
      const pollInterval = setInterval(async () => {
        try {
          const statusResult = await check_job_status(newJobId, chatBotId);
          if (statusResult.status === "completed") {
            clearInterval(pollInterval);
            const aiMessage = {
              id: messages.length + 2,
              type: "ai",
              msg: statusResult.answer,
            };
            setMessages((prevMessages) => [...prevMessages, aiMessage]);
          }
        } catch (statusError) {
          console.error("Error checking job status:", statusError);
        }
      }, 5000);
    } catch (error) {
      console.error("Error making POST request:", error);
    }

    setInput("");
    setAttachedFile(null); // Clear the attached file after sending
  };

  const handleFileUpload = async (fileInfo) => {
    setAttachedFile(fileInfo);
  };

  return (
    <div
      className="sm:col-span-12 flex flex-col bg-gray-900 text-white w-full min-h-screen bg-fixed bg-center bg-cover"
      style={{
        backgroundImage: `url('https://publicsamassets.s3.amazonaws.com/img/sam_ai_sapiens.jpg')`,
      }}
    >
      <div className="flex-grow flex flex-col mx-2 my-2 w-full">
        {messages.length > 0 && (
          <div className="flex-grow bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-md overflow-y-auto max-h-[calc(100vh-80px)]">
            <h2 className="text-lg font-semibold mb-2">
              Messages Id: {chatBotId}
            </h2>
            <ul className="space-y-2">
              {messages.map((message) => (
                <li
                  key={message.id}
                  className={`p-2 rounded-lg ${
                    message.type === "human"
                      ? "bg-blue-900 bg-opacity-70 text-blue-200"
                      : "bg-gray-900 bg-opacity-70 text-gray-200"
                  }`}
                >
                  <strong>{message.type === "human" ? "You" : "AI"}:</strong>{" "}
                  <ReactMarkdown>{message.msg}</ReactMarkdown>{" "}
                  {/* Render the message with Markdown support */}
                </li>
              ))}
              {/* Add this div as the last element */}
              <div ref={messagesEndRef} />
            </ul>
          </div>
        )}
        <div className="flex mt-4 w-full">
          <div className="flex-grow flex flex-col">
            {attachedFile && (
              <div className="mb-2 px-3 py-2 bg-gray-700 rounded-lg flex items-center justify-between">
                <span className="text-sm text-gray-200">
                  {attachedFile.name}
                </span>
                <button
                  onClick={() => setAttachedFile(null)}
                  className="text-gray-400 hover:text-gray-200"
                >
                  ×
                </button>
              </div>
            )}
            <div className="flex items-center bg-gray-800 bg-opacity-50 rounded-lg shadow-lg border border-gray-700">
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type a message"
                className="flex-grow p-3 bg-transparent text-white placeholder-gray-400 focus:outline-none"
              />
              <FileUpload
                onFileUpload={handleFileUpload}
                userId={userId}
                chatBotId={chatBotId}
              />
              <button
                onClick={handlePostRequest}
                disabled={!input.trim() && !attachedFile}
                className={`p-3 rounded-r-lg transition-all duration-200 flex items-center justify-center ${
                  input.trim() || attachedFile
                    ? "text-blue-500 hover:text-blue-400"
                    : "text-gray-500"
                }`}
              >
                <FaPaperPlane size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
