import React, { useRef, useState } from "react";
import { Storage } from "aws-amplify";
import { FaPaperclip } from "react-icons/fa";

const FileUpload = ({ onFileUpload, userId, chatBotId }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const constructS3Key = (fileName) => {
    const date = new Date();
    const timestamp = date.getTime();
    return `users/${userId}/chatbots/${chatBotId}/files/${timestamp}-${fileName}`;
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // File size validation (100MB limit)
    if (file.size > 100 * 1024 * 1024) {
      alert("File size must be less than 100MB");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const s3Key = constructS3Key(file.name);

      // Upload to S3
      const result = await Storage.put(s3Key, file, {
        contentType: file.type,
        metadata: {
          userId: userId,
          chatBotId: chatBotId,
          originalName: file.name,
          uploadDate: new Date().toISOString(),
        },
        progressCallback(progress) {
          const percentage = (progress.loaded / progress.total) * 100;
          setUploadProgress(Math.round(percentage));
        },
      });

      // Instead of getting the signed URL, just pass the full S3 path
      const bucketName = "sapienslab-storage-dev-user-files"; // Use your actual bucket name
      onFileUpload({
        key: s3Key,
        type: file.type,
        name: file.name,
        path: s3Key, // Keep just the key for S3 access
        fullPath: `s3://${bucketName}/public/${s3Key}`, // Add full S3 path
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <div className="relative">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelect}
        className="hidden"
        accept="image/*,.pdf,.doc,.docx,.txt"
      />

      <button
        onClick={() => fileInputRef.current?.click()}
        className="p-3 text-gray-400 hover:text-gray-200 transition-colors flex items-center justify-center"
        disabled={isUploading}
        title="Attach file"
      >
        <FaPaperclip size={20} />
      </button>

      {isUploading && (
        <div className="absolute bottom-full left-0 mb-2 w-48 bg-gray-800 rounded-lg p-2 shadow-lg border border-gray-700">
          <div className="text-sm text-gray-300 mb-1">
            Uploading... {uploadProgress}%
          </div>
          <div className="w-full bg-gray-700 rounded-full h-2">
            <div
              className="bg-blue-500 h-2 rounded-full transition-all duration-300"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
