import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Spinner } from "../components/Spinner";
import { getProductImg } from "../utils/getProductImg";
import { get } from "../utils/httpClient";
import { Link } from "react-router-dom";

export function ProductDetails() {
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    get("" + productId).then((data) => {
      setProduct(data);

      setIsLoading(false);
    });
  }, [productId]);

  if (isLoading) {
    return <Spinner />;
  }

  const imageUrl = getProductImg(product.image, 200);
  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center p-2">
      <div className="bg-gray-800 shadow-md rounded-lg p-6 w-full max-w-4xl flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <img
          className="w-full md:w-1/3 rounded-lg"
          src={imageUrl}
          alt={product.name}
        />
        <div className="flex flex-col justify-between w-full md:w-2/3">
          <div>
            <p className="text-2xl font-bold mb-2">
              <strong>AI Sapiens:</strong> {product.name}
            </p>
            <p className="mb-4">
              <strong>Descripción:</strong> {product.description}
            </p>
          </div>
          <Link to="/controlpanel">
            <button className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mt-4">
              Interact
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
