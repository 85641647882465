import React, { useState, useEffect } from "react";
import {
  Authenticator,
  ThemeProvider,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
import { theme } from "../theme";
import Sidebar from "../components/Sidebar";
import ChatInterface from "./ChatInterface";
import axios from "axios";

Amplify.configure(awsExports);

function ControlPanelContent({ user, signOut }) {
  const [selectedChatBotId, setSelectedChatBotId] = useState(null);
  const [subscribedProducts, setSubscribedProducts] = useState([]);

  useEffect(() => {
    if (!user) return; // Ensure the user is available

    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get(
          `https://d4gscm5o97.execute-api.us-east-1.amazonaws.com/prod/subscriptions/${user.attributes.sub}`
        );

        const subscription = response.data;

        // Initialize activeSubscription outside the if-else blocks
        let activeSubscription = [];

        // Check if the subscription is active
        if (subscription.subscription_status === "active") {
          // Access the allowed_bots array
          const allowedBots = subscription.allowed_bots || [];

          console.log("Allowed Bots:", allowedBots);

          activeSubscription = allowedBots;
        } else {
          console.log("No active subscription found.");
          activeSubscription = [];
        }

        setSubscribedProducts(activeSubscription);

        // Automatically select the first available bot ID
        if (activeSubscription.length > 0) {
          setSelectedChatBotId(activeSubscription[0]);
        }
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setSubscribedProducts([]);
      }
    };

    fetchSubscriptions();
  }, [user]);

  return (
    <div className="bg-gray-900 text-white">
      <Sidebar
        user={user}
        setSelectedChatBotId={setSelectedChatBotId}
        subscribedProducts={subscribedProducts}
      />
      <div className="grid-cols-12">
        <div className="sm:col-span-12 bg-gray-800 shadow-lg p-2">
          {selectedChatBotId ? (
            <ChatInterface chatBotId={selectedChatBotId} />
          ) : (
            <h2 className="text-2xl font-bold mb-6">Please select a ChatBot</h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default function ControlPanel() {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator loginMechanisms={["email"]} socialProviders={["google"]}>
        {({ signOut, user }) => {
          // Only render ControlPanelContent when the user is authenticated
          return user ? (
            <ControlPanelContent user={user} signOut={signOut} />
          ) : (
            <div>Loading...</div>
          );
        }}
      </Authenticator>
    </ThemeProvider>
  );
}
