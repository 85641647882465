import { useEffect, useState } from "react";
import { get } from "../utils/httpClient";
import { ProductCard } from "./ProductCard";
import { Spinner } from "./Spinner";

export function ProductsGrid() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get("").then((data) => {
      // convert data.products to an javascript array
      setProducts(data);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <Spinner />; // Show the Spinner component while loading
  }
  // console.log(data);
  return (
    <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {products.map((data) => (
        <ProductCard key={data.id} product={data} />
      ))}
    </ul>
  );
}
