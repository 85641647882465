const API =
  "https://fq39qy3omb.execute-api.us-east-1.amazonaws.com/Prod/products/";

export async function get(path) {
  const result = await fetch(API + path, {
    headers: {},
    // mode: "cors",
  });

  return await result.json();
}
