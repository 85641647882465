import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { FaUserCircle, FaSignOutAlt } from "react-icons/fa"; // Import icons for user and sign out
import { FaHome, FaCog, FaDollarSign } from "react-icons/fa"; // Add icons for home, control panel, and subscription

const Navbar = () => {
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="bg-gradient-to-r from-gray-800 via-gray-900 to-black shadow-lg p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <FaHome size={24} className="text-white sm:hidden" />
          <h1 className="text-3xl font-bold text-white hidden sm:block">
            Sapiens Lab
          </h1>
        </Link>
        <nav className="relative flex items-center space-x-4">
          {!user ? (
            <Link
              to="/controlpanel"
              className="text-lg font-semibold text-white mx-2 hover:text-gray-400 transition duration-300"
            >
              <FaSignOutAlt size={24} className="sm:hidden" />
              <span className="hidden sm:block">Login</span>
            </Link>
          ) : (
            <>
              <Link
                to="/controlpanel"
                className="text-lg font-semibold text-white mx-2 hover:text-gray-400 transition duration-300"
              >
                <FaCog size={24} className="sm:hidden" />
                <span className="hidden sm:block">Control Panel</span>
              </Link>
              <Link
                to="/subscription-management"
                className="text-lg font-semibold text-white mx-2 hover:text-gray-400 transition duration-300"
              >
                <FaDollarSign size={24} className="sm:hidden" />
                <span className="hidden sm:block">Subscription</span>
              </Link>

              <div className="relative">
                <button
                  onClick={toggleDropdown}
                  className="text-lg font-semibold text-white mx-2 hover:text-gray-400 transition duration-300 flex items-center"
                >
                  <FaUserCircle size={24} className="mr-2" />
                  <span className="hidden sm:block">
                    {user.attributes.email}
                  </span>
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                    <button
                      onClick={signOut}
                      className="w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 flex items-center"
                    >
                      <FaSignOutAlt className="mr-2" />
                      <span>Sign Out</span>
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
