import { Link } from "react-router-dom";
import styles from "./ProductCard.module.css";
import { getProductImg } from "../utils/getProductImg";

export function ProductCard({ product }) {
  const imageUrl = getProductImg(product.image, 100);
  return (
    <li className="bg-gray-800 text-white rounded-lg shadow-md overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-xl">
      <Link to={"/product/" + product.id}>
        <img
          width={100}
          height={130}
          className="w-full h-auto object-cover"
          src={imageUrl}
          alt={product.name}
        />
        <div className="p-4">
          <h2 className="text-lg font-semibold">{product.name}</h2>
        </div>
      </Link>
    </li>
  );
}
