// src/theme.js
export const theme = {
  name: "custom-theme",
  tokens: {
    colors: {
      background: {
        primary: { value: "#1a202c" }, // Overall background color
        secondary: { value: "#2d3748" }, // Form container background color
      },
      brand: {
        primary: {
          10: "#1a202c", // Dark background color
          80: "#2d3748", // Slightly lighter background color
        },
        secondary: {
          10: "#2563eb", // Modern blue color for buttons and interactive elements
          80: "#1e40af", // Darker blue for hover state
        },
      },
      font: {
        primary: { value: "#ffffff" }, // Main text color
        secondary: { value: "#a0aec0" }, // Dimmed text color
        interactive: { value: "#ffffff" }, // For buttons and links
        inverse: { value: "#ffffff" }, // For inverse texts (like inside buttons)
      },
    },
    components: {
      button: {
        backgroundColor: { value: "{colors.brand.secondary.10}" }, // Blue background
        color: { value: "#ffffff" }, // White text color
        _hover: {
          backgroundColor: { value: "{colors.brand.secondary.80}" }, // Darker blue on hover
        },
        fontSize: { value: "16px" }, // Ensure the text size is readable and modern
      },
      input: {
        backgroundColor: { value: "{colors.brand.primary.80}" },
        color: { value: "#ffffff" }, // White text color in input fields
        borderColor: { value: "#4a5568" }, // Input field border color
        _focus: {
          borderColor: { value: "{colors.brand.secondary.10}" }, // Blue border on focus
        },
      },
      link: {
        color: { value: "#60a5fa" }, // Lighter blue for links
        _hover: {
          color: { value: "#93c5fd" }, // Even lighter blue on hover
        },
      },
      form: {
        backgroundColor: { value: "{colors.background.secondary}" }, // Form background color
        borderRadius: { value: "8px" }, // Border radius to match the card style
        padding: { value: "20px" },
      },
      authenticator: {
        form: {
          backgroundColor: { value: "{colors.background.secondary}" }, // Form background
          borderRadius: { value: "8px" }, // Border radius
          borderColor: { value: "#2d3748" }, // Border color
          padding: { value: "20px" },
        },
        container: {
          backgroundColor: { value: "{colors.background.primary}" }, // Overall container background
        },
      },
    },
  },
};
